import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import scrollTo from "gatsby-plugin-smoothscroll";

import sampleChapterThumb from "../assets/img/sample_Chapter_1.png";
import sampleContentsThumb from "../assets/img/sample_Contents.png";
import sampleWorksheetThumb from "../assets/img/sample_Worksheet.png";
import sampleTakeawaysThumb from "../assets/img/sample_takeaways.png";

import sampleChapterPdf from "../assets/pdf/Young-Adults-Guide__Chapter-1.pdf";
import sampleContentsPdf from "../assets/pdf/Young-Adults-Guide__Contents.pdf";
import sampleWorksheetPdf from "../assets/pdf/Young-Adults-Guide__Worksheets.pdf";
import sampleTakeawaysPdf from "../assets/pdf/Young-Adults-Guide__Takeaways.pdf";

import "../styles/main.sass";

const Callout = ({ imgLeft, imgSrc, imgAlt, children, width }) => {
	return (
		<div className="callout">
			<div className="container">
				<div
					style={{
						justifyContent: `space-between`,
						flexDirection: imgLeft ? `row` : `row-reverse`,
					}}
					className="columns"
				>
					<div
						className="column is-half"
						style={{
							justifyContent: imgLeft ? `flex-start` : `flex-end`,
						}}
					>
						<img src={imgSrc} width={width} alt={imgAlt} />
					</div>
					<div className="column is-half" style={{ flexDirection: `column` }}>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

const IndexPage = ({ data }) => {
	const { nodes } = data.allImageSharp;

	const bookCover = nodes.find(
		(node) => node.resize.originalName === "bookcover.jpg"
	);
	const bookCoverSrc = bookCover.resize.src;
	const bookCoverWidth = bookCover.resize.width;

	const sign = nodes.find((el) => el.resize.originalName === "sign.jpg");
	const cash = nodes.find((el) => el.resize.originalName === "cash.jpg");
	const bank = nodes.find((el) => el.resize.originalName === "bank.png");
	const colorchart = nodes.find(
		(el) => el.resize.originalName === "colorchart.jpg"
	);

	return (
		<Layout>
			<div className="hero is-fullheight home-hero">
				<div className="hero-body">
					<div className="container">
						<div className="columns custom-columns">
							<div className="column is-half home-content">
								<img
									className="bookcover"
									src={bookCoverSrc}
									width={bookCoverWidth}
									alt="What All Kids Should Know Bookcover"
								/>
							</div>
							<div
								className="column is-half white-text-shadow"
								style={{
									display: `flex`,
									flexDirection: `column`,
									justifyContent: `center`,
								}}
							>
								<div>
									<h1 className="title">
										Teach Financial Literacy. Savings. Investing.
									</h1>
									<p className="subtitle">A Must-Read for All Young Adults</p>
									<p>
										<button
											onClick={() => scrollTo("#callouts")}
											className="button is-link"
										>
											See why
										</button>
									</p>
								</div>
								<div className="mt-6">
									<p>"...potentially genuinely life-changing"</p>
									<p>"...amazingly well-written"</p>
									<p>"Kids and their parents could not find a better guide."</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section id="callouts">
				<Callout imgLeft imgSrc={sign.resize.src} width={cash.resize.width}>
					<h3 className="title">Believe it or not,</h3>
					<p>
						21% of American adults think that winning the lottery is the best
						strategy for their retirement.
					</p>
					<p>
						Statistics show us that Americans, as a whole, are financially
						illiterate and generally horrendous at saving. This book aims to
						correct that by teaching basic saving and investing concepts to
						young adults/millennials in a fun and interesting way.
					</p>
				</Callout>
				<Callout imgSrc={cash.resize.src} width={cash.resize.width}>
					<h3 className="title">Learn the Financial Skills You Need</h3>
					<p>
						Described as "potentially genuinely life changing" and "amazingly
						written," this book covers saving, budgeting and investing, it is a
						must-read for all young adults, teens and everyone older than that
						without basic financial literacy skills. The goal is to instill the
						idea of long term investing – not get rich quick - that everyone
						should know, irrespective of their age.{" "}
					</p>
				</Callout>
				<Callout imgLeft imgSrc={bank.resize.src} width={bank.resize.width}>
					<h3 className="title">Cultivate Healthly Lifelong Habits</h3>
					<p>
						This book encourages good savings and investment habits so readers
						will continue to practice them the rest of their lives.
					</p>
					<p>
						And we all know that the attention spans of some readers may wander,
						so the exciting layout captures and retains their attention with fun
						facts and interesting takeaways.
					</p>
					<p>
						Middle school and high school aged kids who read this book will know
						how to save and invest. And do yourself a favor, parents and
						millenials, you should read it too.
					</p>
				</Callout>
				<Callout imgSrc={colorchart.resize.src} width={colorchart.resize.width}>
					<h3 className="title">Gain Valuable Skills</h3>
					<ul className="list">
						<li>Saving</li>
						<li>Budgeting</li>
						<li>Compounding</li>
						<li>Consumer debt</li>
						<li>Negotiation</li>
						<li>Risk vs. reward</li>
						<li>Buy-and-hold</li>
						<li>Diversification</li>
						<li>Minimizing costs</li>
						<li>Building a portfolio</li>
					</ul>
					<p>
						Examples are used to{" "}
						<em>discourage market timing or chasing returns.</em>
					</p>
				</Callout>
			</section>
			<div id="action-items" className="hero is-small pt-6 pb-6 home-hero">
				<div className="hero-body">
					<div className="container">
						<div className="sample-section">
							<h4 className="title white-text-shadow">Download a sample</h4>
							<div className="item-row-start">
								<div>
									<a href={sampleChapterPdf} title="Chapter 1">
										<img src={sampleChapterThumb} alt="Chapter 1" width="200" />
									</a>
								</div>
								<div>
									<a href={sampleContentsPdf} title="Contents">
										<img src={sampleContentsThumb} alt="Contents" width="200" />
									</a>
								</div>
								<div>
									<a href={sampleWorksheetPdf} title="Worksheet">
										<img
											src={sampleWorksheetThumb}
											alt="Worksheet"
											width="200"
										/>
									</a>
								</div>
								<div>
									<a href={sampleTakeawaysPdf} title="Takeaways">
										<img
											src={sampleTakeawaysThumb}
											alt="Takeaways"
											width="200"
										/>
									</a>
								</div>
							</div>
						</div>
						<div id="purchase" className="columns">
							<div className="column is-half">
								<div>
									<h4 className="title white-text-shadow">
										Get your copy online today
									</h4>
									<p className="white-text-shadow">
										Discounts provided to charities and non-profit organizations
										and for classroom purchases. A portion of all sales will be
										donated to non-profit organizations supporting financial
										literacy. Contact author for speaking engagements.
									</p>
								</div>
							</div>
							<div className="column is-half has-text-centered">
								<div className="columnContainer">
									<div className="buttonContainer">
										<a
											href="https://www.amazon.com/Young-Adults-Guide-Investing-Practical-ebook/dp/B07ZZGXYLB/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1618234737&sr=8-2"
											className="button is-link"
										>
											Kindle
										</a>
										<a
											href="https://www.barnesandnoble.com/w/the-young-adults-guide-to-investing-rob-pivnick/1138428042?ean=9781631585388"
											className="button is-link"
										>
											Nook
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query Images {
		allImageSharp {
			nodes {
				resize(width: 505) {
					src
					width
					originalName
				}
				fluid(quality: 100, sizes: "1200,800") {
					srcSet
					src
					originalName
					sizes
				}
			}
		}
	}
`;
